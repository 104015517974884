<template>
  <div class="float-manage">
    <div class="float-show" @click="judgeLogin">
      <img src="../assets/images/float_icon.png" alt="" />
      <span>个人中心</span>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="mask" v-if="maskShow">
        <div class="login-mask" v-if="loginShow" key="login">
          <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
          <span>登录</span>
          <el-form
            ref="loginForm"
            label-width="auto"
            :rules="loginRule"
            :model="loginForm"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="loginForm.mobile"
                type="number"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 5px">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                maxlength="16"
                @input="
                  loginForm.password = loginForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
                v-model="loginForm.password"
                :onkeyup="(loginForm.password = loginForm.password.trim())"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="width: 90%; margin: 0 auto 20px">
              <div
                style="float: left; color: #409eff; cursor: pointer"
                @click="openMask"
              >
                立即注册
              </div>
              <div
                style="float: right; color: #909399; cursor: pointer"
                @click="openForget"
              >
                忘记密码
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.prevent="loginMethods('loginForm')"
                >登录</el-button
              >
              <el-button @click="closeLoginMask('loginForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="forget-mask" v-if="forgetShow" key="forget">
          <i class="el-icon-close" @click="closeForget('forgetForm')"></i>
          <span>忘记密码</span>
          <el-form
            :rules="forgetRule"
            :model="forgetForm"
            ref="forgetForm"
            label-width="auto"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="number"
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="forgetForm.mobile"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <p slot="label" style="margin: 0"></p>
              <div class="msg-box">
                <el-input
                  type="number"
                  placeholder="请输入短信验证码"
                  prefix-icon="el-icon-lock"
                  v-model="forgetForm.code"
                >
                </el-input>
                <button
                  @click.prevent="sendForgetCode"
                  v-show="countDown === 60"
                >
                  获取验证码
                </button>
                <button v-show="countDown !== 60">
                  重新发送 ({{ countDown }})
                </button>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请输入新密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.password"
                @input="
                  forgetForm.password = forgetForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="real_password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请确认密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.real_password"
                @input="
                  forgetForm.real_password = forgetForm.real_password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="forgetReq('forgetForm')"
                >确定</el-button
              >
              <el-button @click="closeForget('forgetForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="register" v-if="registerShow" key="register">
          <i class="el-icon-close" @click="closeMask('registerForm')"></i>
          <span>注册</span>
          <form>
            <label class="label-name-icon">
              <input
                type="text"
                placeholder="真实姓名"
                @blur="checkName('name-check')"
                v-model="resgisterForm.name"
              />
              <b class="check-mark" ref="name-check">请输入姓名</b>
            </label>
            <label class="label-unit-icon">
              <input
                type="text"
                @blur="checkCompany('company-check', 'company-right-check')"
                placeholder="企业名称"
                v-model="resgisterForm.company_name"
              />
              <b class="check-mark" ref="company-check">请输入完整企业名称</b>
            </label>
            <label class="label-phone-icon">
              <input
                type="number"
                placeholder="手机号"
                @blur="checkMobile('mobile-check')"
                @input="
                  if (resgisterForm.mobile.length > 11)
                    resgisterForm.mobile = resgisterForm.mobile.slice(0, 11)
                "
                v-model="resgisterForm.mobile"
              />
              <b class="check-mark" ref="mobile-check">请输入手机号</b>
            </label>
            <label class="label-code-icon">
              <input
                type="number"
                placeholder="验证码"
                @blur="checkCaptcha('captcha-check')"
                v-model="resgisterForm.code"
              />
              <button
                class="send-code-btn"
                @click.prevent="sendCode"
                v-show="countDown === 60"
              >
                发送验证码
              </button>
              <button
                class="send-code-btn send-disable"
                disabled
                v-show="countDown !== 60"
              >
                重新发送( {{ countDown }} )
              </button>
              <b class="check-mark" ref="captcha-check">请输入验证码 </b>
            </label>
            <label class="label-password-icon">
              <input
                type="password"
                placeholder="密码"
                maxlength="16"
                v-model="resgisterForm.password"
                @blur="checkPwd('password-check')"
                @input="
                  resgisterForm.password = resgisterForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              />
              <b class="check-mark" ref="password-check">请输入密码</b>
            </label>
            <label class="label-password-icon">
              <input
                type="password"
                maxlength="16"
                placeholder="确认密码"
                v-model="resgisterForm.real_password"
                @input="
                  resgisterForm.real_password =
                    resgisterForm.real_password.replace(/[^\w\.\/]/gi, '')
                "
                @blur="checkRePwd('re-password-check')"
              />
              <b class="check-mark" ref="re-password-check">请输入确认密码</b>
            </label>
            <button class="now-register" @click.prevent="registerReq">
              立即注册
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FloatManage",
  data() {
    return {
      maskShow: false,
      registerShow: false,
      loginShow: false,
      forgetShow: false,
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      resgisterForm: {
        name: "",
        company_name: "",
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      countDown: 60,
      forgetRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        code: { required: true, message: "请输入验证码", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
        real_password: {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
      },
      forgetForm: {
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
    }
  },

  components: {},

  computed: {},
  created() {
    this.$store.dispatch("GET_GLOBAL_LOGIN", this.loginMask)
  },
  methods: {
    judgeLogin() {
      if (localStorage.getItem("eft")) {
        this.$router.push("/personal")
      } else {
        // Message.success("请先登录!");
        this.$store.state.loginMethods()
      }
    },
    // 登陆弹窗
    loginMask() {
      this.maskShow = true
      this.loginShow = true
    },
    closeLoginMask(formName) {
      this.maskShow = false
      this.loginShow = false

      this.$refs[formName].resetFields()
    },
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData()
          let { mobile, password } = this.loginForm
          fd.append("mobile", mobile)
          fd.append("password", password)
          this.axios.post("/login/login", fd).then((res) => {
            if (res.data.code === 0) {
              localStorage.setItem("eft", res.data.data.token)
              this.$message.success(res.data.msg)
              this.getheadData()
              this.getIndexData()
              // this.$router.push("/");
              this.$router.go(0)
              setTimeout(() => {
                this.maskShow = false
                this.loginShow = false
                this.$refs[formName].resetFields() //清空表单
              }, 800)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    openMask() {
      this.maskShow = true
      this.registerShow = true
    },
    closeMask() {
      this.maskShow = false
      this.registerShow = false
      for (let i in this.resgisterForm) {
        this.resgisterForm[i] = ""
      }
    },
    sendCode() {
      if (this.resgisterForm.mobile === "") {
        this.$message.error("请输入手机号")
        return
      }
      let fd = new FormData()
      fd.append("mobile", this.resgisterForm.mobile)
      this.axios.post("/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
      let timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          clearInterval(timer)
          this.countDown = 60
        }
      }, 1000)
    },
    openForget() {
      this.maskShow = true
      this.forgetShow = true
    },
    closeForget(formName) {
      this.maskShow = false
      this.forgetShow = false
      this.$refs[formName].resetFields()
    },
    registerReq() {
      let { mobile, password } = this.resgisterForm

      let fd = new FormData()
      for (let item in this.resgisterForm) {
        if (this.resgisterForm[item] === "") {
          this.$message.error("请填写完整")
          return
        } else if (this.resgisterForm.company_name.length < 7) {
          this.checkCompany("company-check")
          return
        } else {
          fd.append(item, this.resgisterForm[item])
        }
      }
      this.axios.post("/login/register", fd).then((res) => {
        console.log(this.resgisterForm)

        if (res.data.code === 0) {
          this.$message.success(res.data.msg)
          setTimeout(() => {
            this.closeMask()
            this.$store.state.loginMethods()
            let fd = new FormData()
            fd.append("mobile", mobile)
            fd.append("password", password)
            this.axios.post("/login/login", fd).then((res) => {
              if (res.data.code === 0) {
                localStorage.setItem("eft", res.data.data.token)
                this.$message.success(res.data.msg)
                setTimeout(() => {
                  // this.maskShow = false
                  // this.loginShow = false
                  // this.$refs[loginForm].resetFields() //清空表单
                  // this.bannerList = []
                  // this.getIndexData()
                  this.$router.go(0)
                }, 800)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }, 800)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 忘记密码
    forgetReq(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData()
          for (let i in this.forgetForm) {
            fd.append(i, this.forgetForm[i])
          }
          // DONE
          this.axios.post("/login/forget_pwd", fd).then((res) => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              setTimeout(() => {
                this.closeForget(formName)
                this.$refs[formName].resetFields()
                this.$store.state.loginMethods()
              }, 800)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    sendForgetCode() {
      if (this.forgetForm.mobile === "") {
        this.$message.error("请输入手机号")
        return
      }
      let fd = new FormData()
      fd.append("mobile", this.forgetForm.mobile)
      fd.append("sign", 1)
      this.axios.post("/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
      let timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          clearInterval(timer)
          this.countDown = 60
        }
      }, 1000)
    },
    getheadData() {
      this.axios.post("/").then((res) => {
        this.myHead = res.data
        this.myBusiness = res.data.business
        this.$store.dispatch(
          "SET_EMPLOYEE_INFO",
          JSON.stringify(res.data.user_info)
        )
        if (res.data.is_has_cert == 0) {
          this.autoCert()
        }
      })
    },
    getIndexData() {
      this.axios.get("/").then((res) => {
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          )
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          )
        }
      })
    },
    // 注册表单验证
    checkName(b) {
      if (this.resgisterForm.name === "") {
        this.$refs[b].style.opacity = 1
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
    checkCompany(b) {
      if (
        this.resgisterForm.company_name === "" ||
        this.resgisterForm.company_name.length < 7
      ) {
        this.$refs[b].style.opacity = 1
        return
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
    checkMobile(b) {
      if (this.resgisterForm.mobile === "") {
        this.$refs[b].style.opacity = 1
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
    checkCaptcha(b) {
      if (this.resgisterForm.captcha === "") {
        this.$refs[b].style.opacity = 1
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
    checkPwd(b) {
      if (this.resgisterForm.password === "") {
        this.$refs[b].style.opacity = 1
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
    checkRePwd(b) {
      if (this.resgisterForm.real_password === "") {
        this.$refs[b].style.opacity = 1
      } else {
        this.$refs[b].style.opacity = 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.float-manage {
  position: fixed;
  bottom: 25%;
  right: 0;
  width: 80px;
  height: 84px;
  background-color: rgba($color: #fff, $alpha: 1);
  z-index: 999;
  border-radius: 5px;

  .float-show {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    border: 1px solid #eee;
    cursor: pointer;

    &:hover {
      color: red;
      img {
        content: url("../assets/images/float_icon_active.png");
      }
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.39);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
    .register {
      max-width: 680px;
      max-height: 647px;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 1% 8%;
      box-sizing: border-box;
      span {
        display: inline-block;
        margin-top: 6%;
        color: #1890ff;
        font-size: 29px;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 46px;
          height: 3px;
          background: #1890ff;
          position: absolute;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
      i {
        position: absolute;
        right: 3%;
        font-size: 24px;
        cursor: pointer;
      }
      form {
        width: 350px;
        max-height: 500px;
        margin: 10% auto;
        label {
          display: block;
          width: 350px;
          height: 40px;
          background: #ffffff;
          border: 1px solid rgba(217, 217, 217, 0.65);
          border-radius: 20px;
          padding: 6px 5px;
          box-sizing: border-box;
          margin-bottom: 24px;
          background: url("../assets/images/sprite.png") no-repeat;
          position: relative;
          &::before {
            content: "*";
            color: red;
            position: absolute;
            top: 50%;
            left: -6%;
            transform: translateY(-50%);
          }
          input {
            width: 80%;
            height: 75%;
            border: 0;
            outline: 0;
            font-size: 16px;
            &::placeholder {
              color: rgba($color: #000000, $alpha: 0.3);
            }
          }

          b {
            font-weight: normal;
            color: #f56c6c;
            font-size: 12px;
            position: absolute;
            left: 0;
            bottom: -50%;
            opacity: 0;
            transition: all 0.5s;
          }
        }
        button.now-register {
          width: 239px;
          height: 40px;
          background: #8bb9fc;
          border-radius: 20px;
          font-size: 15px;
          color: #ffffff;
          border: 0;
          margin-top: 12px;
          cursor: pointer;
        }
        .label-name-icon {
          background-position: 4% -3%;
        }
        .label-unit-icon {
          background-position: 4% 18%;
        }
        .label-phone-icon {
          background-position: 4% 39%;
        }
        .label-code-icon {
          position: relative;
          background-position: 4% 61%;
          button.send-code-btn {
            height: 100%;
            border: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 50px;
            color: #000;
            cursor: pointer;
          }
          .send-disable {
            cursor: not-allowed !important;

            background-color: #e7e8e9;
          }
        }
        .label-password-icon {
          background-position: 4% 82%;
        }
      }
    }
    .login-mask {
      width: 25%;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 20px 50px;
      box-sizing: border-box;
      span {
        display: inline-block;
        margin-top: 6%;
        color: #1890ff;
        font-size: 29px;
        position: relative;
        margin-bottom: 47px;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: #1890ff;
          position: absolute;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
      i {
        position: absolute;
        right: 3%;
        font-size: 24px;
        cursor: pointer;
      }
    }
    .forget-mask {
      width: 25%;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 20px 50px;
      box-sizing: border-box;
      span {
        display: inline-block;
        margin-top: 6%;
        color: #1890ff;
        font-size: 29px;
        position: relative;
        margin-bottom: 47px;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: #1890ff;
          position: absolute;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
      i {
        position: absolute;
        right: 3%;
        font-size: 24px;
        cursor: pointer;
      }
      .msg-box {
        display: flex;
        button {
          width: 40%;
          border: 0;
          background-color: rgba($color: #8bb9fc, $alpha: 0.8);
          border-radius: 50px;
          cursor: pointer;
          color: #fff;

          &:hover {
            background-color: rgba($color: #8bb9fc, $alpha: 0.6);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.mask {
  .login-mask,
  .forget-mask {
    .el-input__inner {
      border-radius: 50px;
    }
    .el-button {
      border-radius: 50px;
      background-color: #8bb9fc;
      border: 0;
      color: #fff;
    }
  }
}
.register {
  .el-autocomplete {
    position: absolute;
    top: 13%;
    left: 10%;
    height: 110%;
    .el-input {
      height: 68%;
    }
    input {
      width: 120%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 16px;
      padding: 0;
      padding-left: 5px;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
</style>
